import { MetaWebsite } from '@moonhatch/gatsby-theme-moonhatch';
import { graphql } from 'gatsby';
import React from 'react';

import { Moonhatch } from '../icons';
import style from './index.module.scss';

export default ({ data, location }) => {
  const { description } = data.site.siteMetadata;

  return (
    <div className={style.container}>
      <MetaWebsite pageDescription={description} pathname={location.pathname} />
      <Moonhatch className={style.logo} />
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        instagram
      }
    }
  }
`;
